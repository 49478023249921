const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const generatePaymentRequestEmail = (customerName, amount, paymentLink) => {
  return `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Payment Request</title>
    <style>
        body { font-family: Arial, sans-serif; margin: 0; padding: 20px; background-color: #f4f4f4; }
        .container { background-color: #fff; padding: 20px; margin: auto; max-width: 600px; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .header, .footer { text-align: center; }
        .logo { max-width: 100px; margin: 0 auto; display: block; }
        .content { margin-top: 20px; }
        .button { display: inline-block; background-color: #007bff; color: #ffffff; padding: 10px 20px; text-decoration: none; border-radius: 5px; margin-top: 20px; }
        .footer { margin-top: 20px; font-size: 12px; text-align: center; color: #aaa; }
    </style>
</head>
<body>
    <div class="container">
        <img src="https://www.dockmaster.com/wp-content/uploads/2023/11/DM-Payments-e1700758615405.png" alt="Dockmaster Payments Logo" class="logo">
        <h1 class="header">Payment Request</h1>
        <p class="content">Dear ${customerName},</p>
        <p class="content">We hope this message finds you well. A payment of <strong>$${amount}</strong> is due for the services rendered. Please use the link below to complete your payment at your earliest convenience.</p>
        <a href="${paymentLink}" class="button">Pay Now</a>
        <p class="content">If you have any questions or concerns regarding this payment, please don't hesitate to contact our customer support team.</p>
        <p class="footer">Thank you for choosing Dockmaster Payments.</p>
    </div>
</body>
</html>
`;
};
const generateStaticPaymentRequestEmail = (customerName, paymentLink) => {
  return `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Payment Request</title>
    <style>
        body { font-family: Arial, sans-serif; margin: 0; padding: 20px; background-color: #f4f4f4; }
        .container { background-color: #fff; padding: 20px; margin: auto; max-width: 600px; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .header, .footer { text-align: center; }
        .logo { max-width: 100px; margin: 0 auto; display: block; }
        .content { margin-top: 20px; }
        .button { display: inline-block; background-color: #007bff; color: #ffffff; padding: 10px 20px; text-decoration: none; border-radius: 5px; margin-top: 20px; }
        .footer { margin-top: 20px; font-size: 12px; text-align: center; color: #aaa; }
    </style>
</head>
<body>
    <div class="container">
        <img src="https://www.dockmaster.com/wp-content/uploads/2023/11/DM-Payments-e1700758615405.png" alt="Dockmaster Payments Logo" class="logo">
        <h1 class="header">Payment Request</h1>
        <p class="content">Dear ${customerName},</p>
        <p class="content">We hope this message finds you well. Please use the link below to complete your payment for the services rendered at your earliest convenience.</p>
        <a href="${paymentLink}" class="button">Pay Now</a>
        <p class="content">If you have any questions or concerns regarding this payment, please don't hesitate to contact our customer support team.</p>
        <p class="footer">Thank you for choosing Dockmaster Payments.</p>
    </div>
</body>
</html>
`;
};

const generatePaymentRequestSms = (customerName, amount, paymentLink) => {
  let msg = "Dear " + customerName + "\n";
  msg +=
    "We hope this message finds you well. A payment of " +
    amount +
    " is due for the services rendered.\n";
  msg +=
    "Please use the link below to complete your payment at your earliest convenience: " +
    paymentLink +
    "\n";
  msg +=
    "If you have any questions or concerns regarding this payment, please don't hesitate to contact our customer support team." +
    "\n";
  msg += "Thank you for choosing Dockmaster Payments." + "\n";
  return msg;
};

const padWithZeros = (string, digits) => {
  string = string.toString(); // Ensure the input is a string
  const zerosNeeded = digits - string.length; // Calculate the number of zeros needed
  return zerosNeeded > 0 ? "0".repeat(zerosNeeded) + string : string; // Prepend zeros if needed
};

const getPaymentReceivedNotification = (
  payment_id,
  payment_amount,
  location,
  customer_id,
  customer_name,
  requested_user,
  date_time,
  card_type,
  last_four,
  auth_code,
  transaction_id,
  location_api_request_information,
  payment_detail_information,
  apply_payment_information,
  logo,
) => {
  
  const generateTableRows = (data, columns) => {
    return data
      .map(
        (entry) => `
          <tr>
              ${columns
                .map((column) => `<td>${entry[column] || ""}</td>`)
                .join("")}
          </tr>
      `,
      )
      .join("");
  };

  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Payment Received Notification</title>
      <style>
        body { font-family: 'Roboto', Arial, sans-serif; padding: 20px; background-color: #f4f4f4; font-size: 14px; }
        .logo { max-width: 100px; margin: 0 auto; display: block; }
        .container { background-color: #fff; padding: 20px; margin: auto; max-width: 800px; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        table { width: 100%; border-collapse: collapse; margin-top: 10px; }
        td, th { border: 1px solid #ddd; padding: 8px; text-align: left; }
        th { background-color: #f0f0f0; }
      </style>
  </head>
  <body>
      <div class="container">
          <img src="${logo}" alt="Dockmaster Payments Logo" class="logo">
          <h5 class="header">${location}</h5>
          <h2 class="header">QADATA1 Customer Payment Notification</h2>
          <h4>We received a payment for ${payment_amount} USD from customer ${customer_id} - ${customer_name}.</h4>
          <table>
              <tr><th>Payment ID:</th><td>${payment_id}</td></tr>
              <tr><th>Customer:</th><td>${customer_id} - ${customer_name}</td></tr>
              <tr><th>Requested User:</th><td>${requested_user}</td></tr>
              <tr><th>Date Time:</th><td>${date_time}</td></tr>
              <tr><th>Card Type:</th><td>${card_type}</td></tr>
              <tr><th>Last Four:</th><td>${last_four}</td></tr>
              <tr><th>Auth Code:</th><td>${auth_code}</td></tr>
              <tr><th>Transaction ID:</th><td>${transaction_id}</td></tr>
          </table>
          
          <h4>Location/API Request Information:</h4>
          <table>
              <tr><th>Loc</th><th>Batch/Ref</th><th>Receipt#</th><th>Amount</th></tr>
              ${generateTableRows(location_api_request_information, [
                "location",
                "batch_ref",
                "receipt_number",
                "amount",
              ])}
          </table>

          <h4>Payment Detail Information:</h4>
          <table>
              <tr><th>Reference</th><th>Description</th><th>Amount</th></tr>
              ${generateTableRows(payment_detail_information, [
                "reference",
                "description",
                "amount",
              ])}
          </table>

          <h4>Apply Payment Information:</h4>
          <table>
              <tr><th>Loc</th><th>Invoice</th><th>Action</th><th>Amount</th></tr>
              ${generateTableRows(apply_payment_information, [
                "location",
                "invoice",
                "depositType",
                "amount",
              ])}
          </table>
      </div>
  </body>
  </html>
  `;
};

const getPaymentReceivedCustomerReceipt = (
  location,
  payment_id,
  payment_amount,
  paymentDetailInfo,
  contactus_email,
  contactus_number,
  logo,
  homeUrl,
) => {
  const generateTableRows = (data) => {
    return data
      .map(
        (entry) => `
        <tr>
            <td>${entry.reference}</td>
            <td>${entry.description || ""}</td>
            <td>$${entry.amount.toFixed(2)}</td>
        </tr>
    `,
      )
      .join("");
  };

  const totalAmount = paymentDetailInfo.reduce(
    (sum, item) => sum + item.amount,
    0,
  );

  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Customer Payment Receipt</title>
      <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet">
      <style>
            body { font-family: 'Lato', Arial, sans-serif; padding: 20px; background-color: #f4f4f4; font-size: 14px; }
            .logo { max-width: 100px; margin: 0 auto; display: block; }
            .container { background-color: #fff; padding: 20px; margin: auto; max-width: 600px; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
            h1, h2, h3, h4, h5 { margin-top: 0; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; margin-bottom: 10px; }
            td, th { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f0f0f0; font-weight: 700; }
            .total { text-align: right; padding-right: 10px; }
        </style>
  </head>
  <body>
      <div class="container">
      <img src="${logo}" alt="Dockmaster Payments Logo" class="logo">
          <h2>Customer Receipt</h2>
          <p>We received your payment for $${payment_amount.toFixed(2)} USD.</p>
          <p>Your payment reference is ${payment_id}.</p>
          
          <h3>Payment Information:</h3>
          <table>
              <tr><th>Reference</th><th>Description</th><th>Amount</th></tr>
              ${generateTableRows(paymentDetailInfo)}
              <tr><td colspan="2" class="total"><strong>Total:</strong></td><td><strong>$${totalAmount.toFixed(
                2,
              )}</strong></td></tr>
          </table>

          <p>Thank you for your payment!</p>
          <p>If you have any questions, please contact us at:</p>
          <p>${contactus_email}<br>${contactus_number}</p>
          <p>Visit us at: <a href="https://${homeUrl}">${homeUrl}</a></p>
      </div>
  </body>
  </html>
  `;
};

const generateStaticPaymentRequestSms = (customerName, paymentLink) => {
  let msg = "Dear " + customerName + "\n";
  msg +=
    "We hope this message finds you well. A payment " +
    "is due for the services rendered.\n";
  msg +=
    "Please use the link below to complete your payment at your earliest convenience: " +
    paymentLink +
    "\n";
  msg +=
    "If you have any questions or concerns regarding this payment, please don't hesitate to contact our customer support team." +
    "\n";
  msg += "Thank you for choosing Dockmaster Payments." + "\n";
  return msg;
};

const roundTo = (num, places) => {
  const multiplier = Math.pow(10, places);
  return Math.round(num * multiplier) / multiplier;
};

const calculateMinorUnit = (amount) => {
  return amount * 100;
};
const getDifferenceInDays = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
function hexToRgba(hex, opacity) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const depositCode = [
  "BD",
  "BS",
  "DS",
  "KD",
  "MD",
  "QU",
  "SL",
  "SO",
  "SS",
  "TR",
  "WO",
  "WS",
  "RD",
  "WD",
];

const depositCodeMapping = {
  BD: "SD",
  BS: "BSD",
  DS: "SD",
  KD: "KD",
  MD: "OA",
  QU: "OA",
  SI: "",
  SL: "SD",
  SO: "SOD",
  SS: "SD",
  TR: "SD",
  WI: "",
  WO: "WOD",
  WS: "SD",
  RD: "RD",
  WD: "WLD",
};

export {
  hexToRgba,
  getCurrentDateTime,
  generatePaymentRequestEmail,
  generatePaymentRequestSms,
  roundTo,
  calculateMinorUnit,
  generateStaticPaymentRequestEmail,
  generateStaticPaymentRequestSms,
  getDifferenceInDays,
  getPaymentReceivedNotification,
  getPaymentReceivedCustomerReceipt,
  depositCode,
  depositCodeMapping,
  padWithZeros,
};
